@import url('./style.css');

.App {
  text-align: center;
  /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
  url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
  auto; */
}
body{
  /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
  url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
  auto !important; */
}

#map {
  height: 100%;
  position: unset !important;  
  /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
  url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
  auto !important;; */
}

#map:hover {  
  /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
  url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
  auto !important; */
}

.logo-text{
  font-weight: 300;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .cursor-pointer{
  cursor: pointer;
} */

.pull-right{
  float: right;
}

.nav{
  position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.gradient-bv{
  background: linear-gradient(149deg,#ff7f00 0%,#4249FE 52%,#39cfed 73%);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.header-container {
  z-index: 9;
  background: #00000082;
  
  /* background: linear-gradient(149deg,#ff7f00 0%,#4249FE 52%,#39cfed 73%); */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  color: white;
  max-width: 100%;
  padding: 10px;
  margin: 0;
}

.sidenav{
  height: 100%;
  width: 256px;
  position: fixed;
  z-index: 1;
  /* top:  1rem; */
  left: 0;
  /* background-color: #002a56; */
  background: linear-gradient(90deg,#ff7f6094 -1%,#4249FE70 59%);
  overflow-x: hidden;
  color: #fff;
}

.sidenav-card{
  /* background-color: #004389; */
  background: linear-gradient(90deg,#6F56CE -1%,#AD6887 96%);
  border: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  /* background-color: #282c34; */
}
.sidenav-card p{
  font-size: smaller;
}

.search{
  width: 100%;
  /* background-color: #002a5670;
  border: 1px solid #002a5670; */
  border-radius: 8px;
  border: #dde6f2;
  font-size: 1rem;
  height: 40px;
  padding: 0 0.5rem;
}
.search :focus{
  border: #dde6f2;
  outline: none !important;
  box-shadow: 0 0 0 0.125rem white;
}

.search-icon{
  align-self: center;
  margin-left: -2rem;
  color: #dde6f2 !important;
}

.card-title.h5{
  font-size: 1rem !important;
}

.ml-256{
  margin-left: 260px;
}

/* #map div:first-child{
  margin-left: 265px;
    margin-top: 56px;
} */




.searchInputWrapper {
  position: relative;
}

.searchInput {
  width: 14rem;
  height: 2rem;
  padding: 0 1rem; 
  border-radius: 2rem;
  font-size: small;
  border: none;
  transition: transform 0.1s ease-in-out;
}

::placeholder {
  color: #a1a1a1;
}

/* hide the placeholder text on focus */

.searchInput:focus {
  outline: none;
  /* transform: scale(1.1);
  transition: all 0.1s ease-in-out; */
}

.searchInputIcon {
  position: absolute;
  right: 0.8rem;
  top: 0.5rem;
  color: #a1a1a1;
  transition: all 0.1s ease-in-out;
}

.container:focus-within > 
.searchInputWrapper > .searchInputIcon {
  right: 0.2rem;
}
.custom-map-control-button {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 6px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  overflow: hidden;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  /* cursor: pointer; */
}
.custom-map-control-button:hover {
  background: rgb(235, 235, 235);
}

.side-icon{
  width:18px;
  height: 18px;
}
.experience-card{  
  overflow: hidden;
}

.vr-locations{
  height:150px;
  overflow: hidden;
}
.vr-locations p{
	cursor: pointer;
}
.overlay{
  position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 9;
}

.no-content{
	color: white;
	position: absolute;
    top: 50%;
    left: 46%;
}

.letter-space{
  letter-spacing: 12px;
}

.beta{
    background: white;    
    color: #0010ff;
    font-size: 10px;
    border-radius: 2px;
    padding: 2px 6px;
    position: absolute;
    margin: 3px 3px;
    font-weight: 600;
}

.social{
  display: flex;
    bottom: 30px;
    position: absolute;
    transform: translateX(24%);
}

.note{
	font-size: x-small;
    font-stretch: expanded;
	bottom: 100px;
    position: absolute;
	text-transform: capitalize;
}

.menu-icon-holder{
	position: absolute;
	/* left: 0px; */
    margin-left: 0px;
}

.menu-icon{
	width: 20px;
    height: 20px;
}



#gradient-canvas {
	--gradient-color-1: #f08d29;
	--gradient-color-2: #ff8000;
	--gradient-color-3: #26d669;
	--gradient-color-4: #ffffff7d
}

:root {
	--primary-font: "Barlow", sans-serif;
	--secondary-font: "Montserrat", sans-serif;
	--accent-color: #4efff6;
	--bv-color-accent-50: #f6ffff;
	--bv-color-accent-100: #edfffe;
	--bv-color-accent-200: #d3fffd;
	--bv-color-accent-300: #b8fffb;
	--bv-color-accent-400: #83fff9;
	--bv-color-accent-500: #4efff6;
	--bv-color-accent-600: #46e6dd;
	--bv-color-accent-700: #3bbfb9;
	--bv-color-accent-800: #2f9994;
	--bv-color-accent-900: #267d79;
	--bv-color-contrast-50: #f7f6ff;
	--bv-color-contrast-100: #f0edff;
	--bv-color-contrast-200: #d9d1ff;
	--bv-color-contrast-300: #c2b5ff;
	--bv-color-contrast-400: #957eff;
	--bv-color-contrast-500: #6746ff;
	--bv-color-contrast-600: #5d3fe6;
	--bv-color-contrast-700: #4d35bf;
	--bv-color-contrast-800: #3e2a99;
	--bv-color-contrast-900: #32227d
}

body {
	font-family: var(--primary-font);
	background: #0b1155;
	background: radial-gradient(circle 780px at 5.2% 51.6%, rgb(5, 8, 114) 0%, rgb(7, 3, 53) 97.5%)
}

.bv-text__gradient-fill {
	background: linear-gradient(149deg, #ff7f00 0%, #4249FE 52%, #4CFFF7 73%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent
}

.bv-text__gradient-fill::-moz-selection {
	background: #7750fe;
	-webkit-text-fill-color: #fff
}

.bv-text__gradient-fill::selection {
	background: #7750fe;
	-webkit-text-fill-color: #fff
}

.bv-text__glow-fill {
	background: linear-gradient(167deg, #46FFE1 0%, #FFFFFF 57%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent
}

.bv-text__glow-fill::-moz-selection {
	background: #37e9f6;
	-webkit-text-fill-color: #fff
}

.bv-text__glow-fill::selection {
	background: #37e9f6;
	-webkit-text-fill-color: #fff
}

.bv-text__gradient-fill--short {
	background: linear-gradient(149deg, #ff5f14 10%, #e5fdff 50%, #48ffa4 85%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent
}

.bv-text__gradient-fill--short::-moz-selection {
	background: #7750fe;
	-webkit-text-fill-color: #fff
}

.bv-text__gradient-fill--short::selection {
	background: #7750fe;
	-webkit-text-fill-color: #fff
}

@-webkit-keyframes rotating {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

@keyframes rotating {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

.bv-home-hero__container {
	width: 100%;
	display: block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: absolute;
	top: 0
}

.bv-home-hero__container--gradient {
	width: 100%;
	height: 60vh;
	min-height: 480px;
	display: block;
	position: relative;
	z-index: -1;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	overflow: hidden;
	-webkit-mask-image: linear-gradient(181deg, rgba(0, 0, 0, 0.6901960784) 0%, rgba(0, 0, 0, 0) 80%);
	mask-image: linear-gradient(181deg, rgba(0, 0, 0, 0.6901960784) 0%, rgba(0, 0, 0, 0) 80%)
}

.bv-home-hero__container--gradient:before {
	content: "";
	position: absolute;
	display: block;
	width: 100%;
	height: calc(100% + 30px);
	right: 0;
	bottom: -4px;
	z-index: 1;
	background: linear-gradient(359deg, rgba(11, 17, 85, 0.7019607843) 10%, rgba(96, 91, 172, 0.1411764706) 60%)
}

.bv-home-hero__container--gradient .bv-home-hero__canvas--wrapper {
	overflow: hidden
}

.bv-home-hero__container--gradient .bv-home-hero__canvas--wrapper:before {
	content: "";
	display: block;
	position: absolute;
	left: -200px;
	top: 60px;
	width: 350px;
	height: 350px;
	z-index: 1;
	-webkit-animation: rotating 210s linear infinite;
	animation: rotating 210s linear infinite;
	-webkit-transform-origin: center;
	transform-origin: center;
	opacity: .35;
	background: url(https://bharatverse.xyz/images/hero-mandala-art.png) no-repeat right top;
	background-size: cover;
	-webkit-mask-image: linear-gradient(141deg, rgb(0, 0, 0) 76%, rgba(0, 0, 0, 0) 100%);
	mask-image: linear-gradient(141deg, rgb(0, 0, 0) 76%, rgba(0, 0, 0, 0) 100%)
}

@media(min-width:640px) {
	.bv-home-hero__container--gradient .bv-home-hero__canvas--wrapper:before {
		left: -200px;
		top: -100px;
		width: 450px;
		height: 450px
	}
}

@media(min-width:768px) {
	.bv-home-hero__container--gradient .bv-home-hero__canvas--wrapper:before {
		left: -180px
	}
}

@media(min-width:1024px) {
	.bv-home-hero__container--gradient .bv-home-hero__canvas--wrapper:before {
		left: -200px;
		top: -180px;
		width: 650px;
		height: 650px
	}
}

.bv-home-hero__container--gradient canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	min-height: 480px;
	top: 0;
	left: 0
}

.bv-hero__content-wrapper {
	max-width: 1680px;
	padding: 0 15px;
	margin: 80px auto 0
}

@media(min-width:1024px) {
	.bv-hero__content-wrapper {
		padding: 0 30px
	}
}

@media(min-width:1680px) {
	.bv-hero__content-wrapper {
		padding: 0 80px;
		margin: 220px auto 0
	}
}

.bv-hero__content-wrapper .bv-hero__content {
	width: 100%;
	text-align: center
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__heading {
	font-size: 2.2em;
	font-weight: 700;
	line-height: 1.15;
	letter-spacing: -.025em;
	max-width: 520px;
	margin: 0 auto
}

@media(min-width:768px) {
	.bv-hero__content-wrapper .bv-hero__content .bv-hero__heading {
		font-size: 3em;
		max-width: 980px
	}
}

@media(min-width:1024px) {
	.bv-hero__content-wrapper .bv-hero__content .bv-hero__heading {
		font-size: 3.85em
	}
}

@media(min-width:1280px) {
	.bv-hero__content-wrapper .bv-hero__content .bv-hero__heading {
		font-size: 4.25em
	}
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__heading .ka {
	font-family: amita, cursive;
	font-weight: 700;
	line-height: 1
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__heading .ka::-moz-selection {
	background: #7750fe;
	-webkit-text-fill-color: #fff
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__heading .ka::selection {
	background: #7750fe;
	-webkit-text-fill-color: #fff
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__subheading {
	font-weight: 600;
	font-size: 1.25em;
	display: block;
	margin: 0 0 .75em;
	letter-spacing: .045em
}

@media(min-width:768px) {
	.bv-hero__content-wrapper .bv-hero__content .bv-hero__subheading {
		font-size: 1.45em
	}
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta {
	margin-top: 30px;
	max-width: 200px
}

@media(min-width:640px) {
	.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta {
		max-width: unset
	}
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link {
	font-family: var(--secondary-font);
	color: #fff;
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 10em;
	font-weight: 600;
	font-size: 17px;
	letter-spacing: .02em;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: .45s;
	transition: .45s;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

@media(min-width:640px) {
	.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		padding: 15px 30px 15px 20px
	}
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link.bv-hero__cta-link--primary {
	background: #46d1ff;
	color: #060415
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link.bv-hero__cta-link--primary:hover {
	background: #52d0fa;
	-webkit-box-shadow: 0 0 100px 0 rgba(70, 209, 255, .6196078431), 0 0 9px 3px rgba(31, 199, 255, .6901960784);
	box-shadow: 0 0 100px 0 rgba(70, 209, 255, .6196078431), 0 0 9px 3px rgba(31, 199, 255, .6901960784)
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link.bv-hero__cta-link--primary .bv-hero__cta-icon {
	-webkit-transform: scale(1.2);
	transform: scale(1.2)
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link.bv-hero__cta-link--secondary {
	position: relative
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link.bv-hero__cta-link--secondary:hover {
	-webkit-box-shadow: 0 0 100px 0 #594fff, 0 0 25px -10px #594fff;
	box-shadow: 0 0 100px 0 #594fff, 0 0 25px -10px #594fff
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link.bv-hero__cta-link--secondary:hover:before {
	background: linear-gradient(332deg, #C65CFE 0%, #4249FE 47%)
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link.bv-hero__cta-link--secondary:before {
	content: "";
	position: absolute;
	z-index: -1;
	inset: 0;
	background: linear-gradient(162deg, #C65CFE 0%, #4249FE 50%, #4CFFF7 100%);
	padding: 3px;
	border-radius: 10em;
	-webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	-webkit-transition: background .45s;
	transition: background .45s
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link.bv-hero__cta-link--secondary .bv-hero__cta-icon {
	-webkit-transform: translateY(-2px) scale(1.12);
	transform: translateY(-2px) scale(1.12)
}

.bv-hero__content-wrapper .bv-hero__content .bv-hero__cta .bv-hero__cta-link .bv-hero__cta-icon {
	display: block;
	width: 33px;
	height: auto;
	margin-right: 10px
}

.bv-navbar__container {
	margin: 0 auto;
	padding: 0 15px;
	position: fixed;
	z-index: 1001;
	width: 100%;
	top: 0;
	-webkit-transition: .45s;
	transition: .45s
}

@media(max-width:1279px) {
	.bv-navbar__container.headroom--not-top {
		background: rgba(0, 0, 0, .25)
	}

	.bv-navbar__container.headroom--not-top .bv-logo-text:after {
		-webkit-filter: brightness(2.5);
		filter: brightness(2.5)
	}
}

@media(min-width:1280px) {
	.bv-navbar__container.headroom--not-top:not(.headroom--pinned) {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px)
	}
}

@media(min-width:1024px) {
	.bv-navbar__container {
		padding: 0 30px
	}
}

@media(min-width:1680px) {
	.bv-navbar__container {
		padding: 0 80px
	}
}

.bv-navbar__container .bv-navbar__content {
	display: block;
	padding: 0px 0;
	position: relative;
	-webkit-transition: .45s;
	transition: .45s
}

@media(min-width:1280px) {
	.bv-navbar__container .bv-navbar__content {
		padding: 30px 0
	}
}

@media(min-width:1280px) {
	.bv-navbar__container.headroom {
		-webkit-transition: background .15s, -webkit-transform .45s;
		transition: background .15s, -webkit-transform .45s;
		transition: transform .45s, background .15s;
		transition: transform .45s, background .15s, -webkit-transform .45s;
		will-change: transform
	}

	.bv-navbar__container.headroom.headroom--top {
		background: 0 0
	}

	.bv-navbar__container.headroom.headroom--pinned {
		-webkit-transform: translateY(0);
		transform: translateY(0)
	}

	.bv-navbar__container.headroom.headroom--pinned.headroom--not-top {
		background: rgba(0, 0, 0, .35)
	}

	.bv-navbar__container.headroom.headroom--pinned.headroom--not-top .bv-logo-text {
		-webkit-transform: scale(.35);
		transform: scale(.35)
	}

	.bv-navbar__container.headroom.headroom--pinned.headroom--not-top .bv-logo-text:after {
		-webkit-filter: brightness(10);
		filter: brightness(10)
	}

	.bv-navbar__container.headroom.headroom--pinned.headroom--not-top .bv-navbar__content {
		padding: 0px 0
	}

	.bv-navbar__container.headroom.headroom--pinned.headroom--not-top .bv-navbar__content .bv-navbar__submenu-wrapper li {
		padding: 0;
	}

	.bv-navbar__container.headroom.headroom--pinned.headroom--not-top .bv-navbar__content .bv-navbar__submenu-wrapper li a {
		-webkit-transform: scale(.75);
		transform: scale(.75)
	}

	.bv-navbar__container.headroom.headroom--unpinned {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px)
	}
}

.bv-navbar__container--spacer {
	height: 83px;
	position: static;
	display: block
}

@media(min-width:640px) {
	.bv-navbar__container--spacer {
		height: 90px
	}
}

@media(min-width:768px) {
	.bv-navbar__container--spacer {
		height: 97px
	}
}

@media(min-width:1024px) {
	.bv-navbar__container--spacer {
		height: 100px
	}
}

.bv-logo-text {
	font-family: var(--secondary-font);
	font-size: 54px;
	-webkit-transform: scale(.45);
	transform: scale(.45);
	text-align: center;
	line-height: 1;
	margin: 0;
	font-weight: 700;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #fff;
	text-shadow: -1px -1px 0 #20758a, 1px -1px 0 #20758a, -1px 1px 0 #20758a, 1px 1px 0 #20758a, 1px 0 0 #6efbf8, 0 1px 0 #6efbf8, 2px 1px 0 #6efbf8, 1px 2px 0 #6efbf8, 3px 2px 0 #6efbf8, 2px 3px 0 #6efbf8, 4px 3px 0 #6efbf8, 3px 4px 0 #6efbf8, 5px 4px 0 #6efbf8, 3px 5px 0 #20758a, 6px 5px 0 #20758a, -1px 2px 0 #000, 0 3px 0 #20758a, 1px 4px 0 #20758a, 2px 5px 0 #20758a, 2px -1px 0 #20758a, 3px 0 0 #20758a, 4px 1px 0 #20758a, 5px 2px 0 #20758a, 6px 3px 0 #20758a, 7px 4px 0 #20758a, 2px 7px 28px #00fff7
}

.headroom .bv-logo-text {
	-webkit-transition: -webkit-transform .45s;
	transition: -webkit-transform .45s;
	transition: transform .45s;
	transition: transform .45s, -webkit-transform .45s
}

@media(min-width:768px) {
	.bv-logo-text {
		/* font-size: 68px */
	}
}

.bv-logo-text:after {
	content: "";
	display: block;
	width: 180px;
	height: 180px;
	background: url(https://bharatverse.xyz/images/bharat-mandal-art.svg) center center no-repeat transparent;
	background-size: cover;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -90px;
	margin-top: -90px;
	-webkit-transform-origin: 0%;
	transform-origin: 0%;
	z-index: -1;
	-webkit-animation: rotating 80s linear infinite;
	animation: rotating 80s linear infinite;
	-webkit-transform-origin: center;
	transform-origin: center;
	border-radius: 100%;
	opacity: .45
}

.bv-navbar__menu-wrapper {
	list-style: none;
	position: relative
}

.bv-navbar__menu-wrapper li {
	padding: 0 10px
}

.bv-navbar__menu-wrapper li a {
	display: block;
	padding: 8px 5px;
	color: #fff;
	font-family: var(--secondary-font);
	font-weight: 800;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	letter-spacing: -.015em
}

@media(min-width:1680px) {
	.bv-navbar__menu-wrapper li a {
		padding: 8px 10px
	}
}

.bv-navbar__menu-wrapper>.menu__item-underline {
	position: absolute;
	content: "";
	bottom: 25px;
	left: 0;
	width: 0;
	height: 0;
	z-index: -1;
	-webkit-box-shadow: 3px 0 50px 5px #fffedb;
	box-shadow: 3px 0 50px 5px #fffedb;
	border-radius: 12px
}

.bv-navbar__submenu-wrapper {
	list-style: none;
	padding: 0;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.bv-navbar__submenu-wrapper li {
	padding: 0 5px;
	-webkit-transition: .45s;
	transition: .45s;
}

.bv-navbar__submenu-wrapper li a {
	display: block;
	-webkit-user-select: unset;
	-moz-user-select: unset;
	-ms-user-select: unset;
	user-select: unset;
	width: 30px;
	height: auto;
	-webkit-transition: .45s;
	transition: .45s;
	-webkit-transform-origin: center;
	transform-origin: center
}

.bv-navbar__submenu-wrapper li a svg path {
	-webkit-transition: fill .45s;
	transition: fill .45s
}

.bv-navbar__submenu-wrapper li a svg path.icon-bg {
	fill: rgba(15, 96, 224, .7882352941)
}

.bv-navbar__submenu-wrapper li a svg path.icon-shape {
	fill: #fff
}

@media(min-width:768px) {
	.bv-navbar__submenu-wrapper li a {
		width: 37px
	}
}

.bv-navbar__submenu-wrapper li a:hover svg .icon-bg {
	fill: rgba(15, 95, 224, .4901960784)
}

.bv-navbar__submenu-wrapper li a:hover svg .icon-shape {
	fill: var(--bv-color-accent-500)
}

.bv-navbar__submenu-wrapper li a img {
	border-radius: 100%
}

.bv-navbat__hamburger-menu {
	display: block;
	width: 30px;
	height: 23px;
	position: relative;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	-ms-touch-action: none;
	touch-action: none
}

.bv-navbat__hamburger-menu span {
	display: block;
	position: absolute;
	height: 3px;
	width: 50%;
	background: #fff;
	opacity: 1;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out
}

.bv-navbat__hamburger-menu span:nth-child(2n) {
	left: 50%;
	border-radius: 0 3px 3px 0
}

.bv-navbat__hamburger-menu span:nth-child(odd) {
	left: 0;
	border-radius: 3px 0 0 3px
}

.bv-navbat__hamburger-menu span:first-child,
.bv-navbat__hamburger-menu span:nth-child(2) {
	top: 0
}

.bv-navbat__hamburger-menu span:nth-child(3),
.bv-navbat__hamburger-menu span:nth-child(4) {
	top: 9px
}

.bv-navbat__hamburger-menu span:nth-child(5),
.bv-navbat__hamburger-menu span:nth-child(6) {
	top: 18px
}

.bv-navbat__hamburger-menu.is-open span:first-child,
.bv-navbat__hamburger-menu.is-open span:nth-child(6) {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}

.bv-navbat__hamburger-menu.is-open span:nth-child(2),
.bv-navbat__hamburger-menu.is-open span:nth-child(5) {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg)
}

.bv-navbat__hamburger-menu.is-open span:first-child {
	left: 5px;
	top: 9px
}

.bv-navbat__hamburger-menu.is-open span:nth-child(2) {
	left: calc(50% - 5px);
	top: 9px
}

.bv-navbat__hamburger-menu.is-open span:nth-child(3) {
	left: -50%;
	opacity: 0
}

.bv-navbat__hamburger-menu.is-open span:nth-child(4) {
	left: 100%;
	opacity: 0
}

.bv-navbat__hamburger-menu.is-open span:nth-child(5) {
	left: 5px;
	top: 14px
}

.bv-navbat__hamburger-menu.is-open span:nth-child(6) {
	left: calc(50% - 5px);
	top: 14px
}

.bv-mobile__menu {
	--td: 150ms;
	--te: cubic-bezier(0.215, 0.61, 0.355, 1);
	--color-primary: #18181A;
	--color-secondary: #75757C;
	--color-dark: #364C62;
	--color-light: #F5F5F5;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	left: -100%;
	position: fixed;
	z-index: 999;
	top: 0;
	-webkit-transition: left 0s calc(var(--td) * 2), -webkit-transform 0s calc(var(--td) * 2);
	transition: left 0s calc(var(--td) * 2), -webkit-transform 0s calc(var(--td) * 2);
	transition: left 0s calc(var(--td) * 2), transform 0s calc(var(--td) * 2);
	transition: left 0s calc(var(--td) * 2), transform 0s calc(var(--td) * 2), -webkit-transform 0s calc(var(--td) * 2);
	width: 100%
}

.bv-mobile__menu:after,
.bv-mobile__menu:before {
	content: "";
	background-color: var(--color-primary);
	height: 50%;
	left: 0;
	position: absolute;
	-webkit-transform: translateX(-110%);
	transform: translateX(-110%);
	-webkit-transform-origin: 0 50%;
	transform-origin: 0 50%;
	-webkit-transition: -webkit-transform calc(var(--td) * 2) var(--td) var(--te);
	transition: -webkit-transform calc(var(--td) * 2) var(--td) var(--te);
	transition: transform calc(var(--td) * 2) var(--td) var(--te);
	transition: transform calc(var(--td) * 2) var(--td) var(--te), -webkit-transform calc(var(--td) * 2) var(--td) var(--te);
	width: 100%;
	z-index: -100
}

.bv-mobile__menu:before {
	top: 0
}

.bv-mobile__menu:after {
	bottom: 0
}

.bv-mobile__menu .bv-mobile__menu-item {
	opacity: 0;
	-webkit-transform: translateX(-1rem);
	transform: translateX(-1rem);
	-webkit-transition: opacity var(--td) var(--te), -webkit-transform var(--td) var(--te);
	transition: opacity var(--td) var(--te), -webkit-transform var(--td) var(--te);
	transition: opacity var(--td) var(--te), transform var(--td) var(--te);
	transition: opacity var(--td) var(--te), transform var(--td) var(--te), -webkit-transform var(--td) var(--te)
}

@media(max-width:1439px) {
	.bv-mobile__menu.is-open {
		left: 0;
		-webkit-transition: -webkit-transform 0s;
		transition: -webkit-transform 0s;
		transition: transform 0s;
		transition: transform 0s, -webkit-transform 0s
	}

	.bv-mobile__menu.is-open:after,
	.bv-mobile__menu.is-open:before {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transition-delay: 0s;
		transition-delay: 0s
	}

	.bv-mobile__menu.is-open:after {
		-webkit-transition-delay: calc(var(--td)/2);
		transition-delay: calc(var(--td)/2)
	}

	.bv-mobile__menu.is-open .bv-mobile__menu-item {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transition: opacity calc(var(--td) * 2) var(--te), -webkit-transform calc(var(--td) * 2) var(--te);
		transition: opacity calc(var(--td) * 2) var(--te), -webkit-transform calc(var(--td) * 2) var(--te);
		transition: opacity calc(var(--td) * 2) var(--te), transform calc(var(--td) * 2) var(--te);
		transition: opacity calc(var(--td) * 2) var(--te), transform calc(var(--td) * 2) var(--te), -webkit-transform calc(var(--td) * 2) var(--te)
	}

	.bv-mobile__menu.is-open .bv-mobile__menu-item:first-child {
		-webkit-transition-delay: calc(var(--td) * 2 * (1 * 0.25));
		transition-delay: calc(var(--td) * 2 * (1 * 0.25));
		z-index: -1
	}

	.bv-mobile__menu.is-open .bv-mobile__menu-item:nth-child(2) {
		-webkit-transition-delay: calc(var(--td) * 2 * (2 * 0.25));
		transition-delay: calc(var(--td) * 2 * (2 * 0.25));
		z-index: -2
	}

	.bv-mobile__menu.is-open .bv-mobile__menu-item:nth-child(3) {
		-webkit-transition-delay: calc(var(--td) * 2 * (3 * 0.25));
		transition-delay: calc(var(--td) * 2 * (3 * 0.25));
		z-index: -3
	}

	.bv-mobile__menu.is-open .bv-mobile__menu-item:nth-child(4) {
		-webkit-transition-delay: calc(var(--td) * 2 * (4 * 0.25));
		transition-delay: calc(var(--td) * 2 * (4 * 0.25));
		z-index: -4
	}

	.bv-mobile__menu.is-open .bv-mobile__menu-item:nth-child(5) {
		-webkit-transition-delay: calc(var(--td) * 2 * (5 * 0.25));
		transition-delay: calc(var(--td) * 2 * (5 * 0.25));
		z-index: -5
	}
}

.bv-mobile__menu .bv-mobile__menu-items {
	position: relative;
	text-align: center;
	z-index: 1
}

.bv-mobile__menu .bv-mobile__menu-items>.bv-mobile__menu-item {
	font-family: var(--secondary-font);
	font-size: 3.75vh
}

@media(min-width:768px) {
	.bv-mobile__menu .bv-mobile__menu-items>.bv-mobile__menu-item {
		font-size: 4.5vh
	}
}

.bv-mobile__menu .bv-mobile__menu-items>.bv-mobile__menu-item .bv-mobile__menu-item--link {
	color: var(--bv-color-accent-500);
	font-weight: 600;
	letter-spacing: -.015em;
	text-transform: uppercase
}

.bv-mobile__menu .bv-mobile__menu-items>.bv-mobile__menu-item .bv-mobile__menu-item--section {
	font-weight: 300;
	color: #fff;
	letter-spacing: -.045em;
	padding: 1em 0 0
}

.bv-mobile__menu .bv-mobile__menu-items .bv-mobile__submenu {
	margin: 0;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.bv-mobile__menu .bv-mobile__menu-items .bv-mobile__submenu .bv-mobile__submenu-item {
	margin: 0 8px
}

.bv-mobile__menu .bv-mobile__menu-items .bv-mobile__submenu .bv-mobile__submenu-item .bv-mobile__submenu-link {
	color: var(--bv-color-accent-500);
	font-size: 18px;
	font-weight: 600;
	letter-spacing: -.015em;
	text-transform: uppercase
}

body.menu-is-open {
	overflow: hidden
}

.bv-india__grid-bg--container {
	position: absolute;
	z-index: -1;
	top: 0;
	-webkit-mask-image: linear-gradient(181deg, black 32%, rgba(0, 0, 0, 0) 100%);
	mask-image: linear-gradient(181deg, black 32%, rgba(0, 0, 0, 0) 100%)
}

.bv-india__grid-bg--wrapper {
	-webkit-mask-image: linear-gradient(175deg, rgba(0, 0, 0, 0) 1%, rgb(0, 0, 0) 53%);
	mask-image: linear-gradient(175deg, rgba(0, 0, 0, 0) 1%, rgb(0, 0, 0) 53%)
}

.bv-india__grid-bg--wrapper .bv-india__grid-bg--img {
	width: 100vw;
	-o-object-fit: cover;
	object-fit: cover;
	height: auto;
	-webkit-mask-image: linear-gradient(175deg, rgb(0, 0, 0) 43%, rgba(0, 0, 0, 0) 91%);
	mask-image: linear-gradient(175deg, rgb(0, 0, 0) 43%, rgba(0, 0, 0, 0) 91%)
}

.bv-overview__wrapper {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1680px
}

@media(min-width:1024px) {
	.bv-overview__wrapper {
		padding: 0 30px
	}
}

@media(min-width:1680px) {
	.bv-overview__wrapper {
		padding: 0 80px
	}
}

.bv-overview__wrapper .bv-overview__container {
	padding-top: 200px
}

@media(min-width:640px) {
	.bv-overview__wrapper .bv-overview__container {
		max-width: 500px;
		margin-left: auto;
		margin-right: 0;
		padding-top: 200px
	}
}

@media(min-width:768px) {
	.bv-overview__wrapper .bv-overview__container {
		padding-top: 200px;
		max-width: 500px
	}
}

@media(min-width:1024px) {
	.bv-overview__wrapper .bv-overview__container {
		max-width: 600px;
		padding-top: 200px
	}
}

@media(min-width:1280px) {
	.bv-overview__wrapper .bv-overview__container {
		max-width: 740px;
		padding-top: 200px
	}
}

@media(min-width:1440px) {
	.bv-overview__wrapper .bv-overview__container {
		padding-top: 300px
	}
}

@media(min-width:1680px) {
	.bv-overview__wrapper .bv-overview__container {
		padding-top: 380px
	}
}

.bv-overview__wrapper .bv-overview__container .bv-overview__heading {
	font-family: var(--secondary-font);
	letter-spacing: -.045em;
	font-weight: 700;
	line-height: 1.15;
	margin: 0 0 1em;
	font-size: 1.8em;
	text-align: left
}

@media(min-width:640px) {
	.bv-overview__wrapper .bv-overview__container .bv-overview__heading {
		font-size: 2em
	}
}

@media(min-width:768px) {
	.bv-overview__wrapper .bv-overview__container .bv-overview__heading {
		font-size: 2.15em
	}
}

@media(min-width:1024px) {
	.bv-overview__wrapper .bv-overview__container .bv-overview__heading {
		font-size: 2.45em
	}
}

@media(min-width:1440px) {
	.bv-overview__wrapper .bv-overview__container .bv-overview__heading {
		font-size: 3em
	}
}

.bv-overview__wrapper .bv-overview__container .bv-overview__text {
	margin-bottom: 1.45em;
	line-height: 1.45;
	font-size: 1.15em;
	text-align: left
}

@media(min-width:1024px) {
	.bv-overview__wrapper .bv-overview__container .bv-overview__text {
		font-size: 1.35em
	}
}

.bv-overview__icons-container {
	padding-top: 20px
}

@media(min-width:640px) {
	.bv-overview__icons-container {
		padding-top: 80px
	}
}

@media(min-width:1024px) {
	.bv-overview__icons-container {
		padding-top: 140px
	}
}

.bv-overview__icons-container .bv-overview__subheading {
	font-family: var(--secondary-font);
	font-weight: 500;
	font-size: 1.15em;
	line-height: 1.45;
	text-align: left
}

@media(min-width:1024px) {
	.bv-overview__icons-container .bv-overview__subheading {
		font-size: 1.45em;
		max-width: 30em
	}
}

.bv-overview__icons-container .bv-overview__icons {
	padding-top: 40px
}

.bv-overview__icons-container .bv-overview__icons .bv-overview__icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 60px;
	height: 60px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start
}

@media(min-width:640px) {
	.bv-overview__icons-container .bv-overview__icons .bv-overview__icon {
		width: 80px;
		height: 80px
	}
}

.bv-overview__icons-container .bv-overview__icons .bv-overview__icon>div {
	display: block;
	width: 100%;
	height: 100%
}

.bv-overview__icons-container .bv-overview__icons .bv-overview__icon-heading {
	font-family: var(--secondary-font);
	font-weight: 700;
	letter-spacing: -.01em;
	font-size: 18px;
	margin: .45em 0
}

@media(min-width:768px) {
	.bv-overview__icons-container .bv-overview__icons .bv-overview__icon-heading {
		font-size: 20px
	}
}

@media(min-width:1024px) {
	.bv-overview__icons-container .bv-overview__icons .bv-overview__icon-heading {
		font-size: 22px
	}
}

.bv-overview__icons-container .bv-overview__icons .bv-overview__icon-text {
	font-size: 16px;
	line-height: 1.5
}

@media(min-width:768px) {
	.bv-overview__icons-container .bv-overview__icons .bv-overview__icon-text {
		font-size: 18px
	}
}

@media(min-width:768px) {
	.bv-overview__icons-container .bv-overview__icons .bv-overview__icon-text {
		max-width: 23em
	}
}

.bv-india__map-bg--container {
	position: absolute;
	z-index: -1;
	top: 0;
	-webkit-mask-image: linear-gradient(46deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%);
	mask-image: linear-gradient(46deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%)
}

.bv-india__map-bg--wrapper {
	-webkit-mask-image: linear-gradient(175deg, rgba(0, 0, 0, 0) 6%, rgb(0, 0, 0) 53%);
	mask-image: linear-gradient(175deg, rgba(0, 0, 0, 0) 6%, rgb(0, 0, 0) 53%)
}

.bv-india__map-bg--wrapper .bv-india__map-bg--img {
	width: 100vw;
	-o-object-fit: cover;
	object-fit: cover;
	height: auto;
	-webkit-mask-image: linear-gradient(175deg, rgb(0, 0, 0) 43%, rgba(0, 0, 0, 0) 91%);
	mask-image: linear-gradient(175deg, rgb(0, 0, 0) 43%, rgba(0, 0, 0, 0) 91%)
}

.bv-community__wrapper {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1680px
}

@media(min-width:1024px) {
	.bv-community__wrapper {
		padding: 0 30px
	}
}

@media(min-width:1680px) {
	.bv-community__wrapper {
		padding: 0 80px
	}
}

.bv-community__wrapper .bv-community__container {
	padding-top: 200px
}

@media(min-width:640px) {
	.bv-community__wrapper .bv-community__container {
		max-width: 500px;
		margin-left: auto;
		margin-right: 0;
		padding-top: 200px
	}
}

@media(min-width:768px) {
	.bv-community__wrapper .bv-community__container {
		padding-top: 200px;
		max-width: 500px
	}
}

@media(min-width:1024px) {
	.bv-community__wrapper .bv-community__container {
		max-width: 600px;
		padding-top: 200px
	}
}

@media(min-width:1280px) {
	.bv-community__wrapper .bv-community__container {
		max-width: 780px;
		padding-top: 200px
	}
}

@media(min-width:1440px) {
	.bv-community__wrapper .bv-community__container {
		padding-top: 300px
	}
}

@media(min-width:1680px) {
	.bv-community__wrapper .bv-community__container {
		padding-top: 380px
	}
}

.bv-community__wrapper .bv-community__container .bv-community__heading {
	font-family: var(--secondary-font);
	letter-spacing: -.01em;
	font-weight: 800;
	line-height: 1.15;
	margin: 0 0 1em;
	font-size: 1.65em;
	text-align: left;
	text-transform: uppercase;
	background: linear-gradient(149deg, #ff5f14 10%, #e5fdff 50%, #48ffa4 85%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent
}

@media(min-width:640px) {
	.bv-community__wrapper .bv-community__container .bv-community__heading {
		font-size: 2em
	}
}

@media(min-width:768px) {
	.bv-community__wrapper .bv-community__container .bv-community__heading {
		font-size: 35px
	}
}

@media(min-width:1024px) {
	.bv-community__wrapper .bv-community__container .bv-community__heading {
		font-size: 2.45em
	}
}

@media(min-width:1024px) {
	.bv-community__wrapper .bv-community__container .bv-community__heading {
		font-size: 45px
	}
}

@media(min-width:1440px) {
	.bv-community__wrapper .bv-community__container .bv-community__heading {
		margin: 0 0 80px
	}
}

.bv-community__wrapper .bv-community__container .bv-community__text {
	margin-bottom: 1.45em;
	line-height: 1.45;
	font-size: 1.15em;
	text-align: left
}

@media(min-width:1024px) {
	.bv-community__wrapper .bv-community__container .bv-community__text {
		font-size: 1.35em
	}
}

.bv-community__wrapper .bv-community__container .bv-community__subheading {
	font-family: var(--secondary-font);
	font-weight: 500;
	font-size: 1.15em;
	line-height: 1.25;
	text-align: left
}

@media(min-width:1024px) {
	.bv-community__wrapper .bv-community__container .bv-community__subheading {
		font-size: 1.45em
	}
}

.bv-community__wrapper .bv-community__container .bv-community__icons {
	margin-top: 40px
}

.bv-community__wrapper .bv-community__container .bv-community__icons .bv-community__icon-heading {
	font-family: var(--secondary-font);
	font-weight: 600;
	font-size: 18px;
	padding-bottom: .45em
}

.bv-community__wrapper .bv-community__container .bv-community__icons .bv-community__icon-text {
	font-size: 1em
}

@media(min-width:1024px) {
	.bv-community__wrapper .bv-community__container .bv-community__icons .bv-community__icon-text {
		max-width: 500px;
		font-size: 18px
	}
}

.bv-community__wrapper .bv-community__container .bv-community__icons .bv-community__icon-text>a {
	color: #5eead4;
	position: relative;
	background-image: -webkit-gradient(linear, left top, right top, color-stop(30%, rgb(94, 234, 212)), to(rgba(94, 234, 212, 0)));
	background-image: linear-gradient(90deg, rgb(94, 234, 212) 30%, rgba(94, 234, 212, 0) 100%);
	background-position: 0% 104%;
	background-repeat: no-repeat;
	background-size: 0% 2px;
	-webkit-transition: background-size .5s;
	transition: background-size .5s
}

.bv-community__wrapper .bv-community__container .bv-community__icons .bv-community__icon-text>a:hover {
	background-size: 100% 2px
}

.bv-community__wrapper .bv-community__container .bv-community__icons .bv-community__icon-text strong {
	font-weight: 500
}

.bv-community__wrapper .bv-community__container .bv-community__icons .bv-community__icon-box {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start
}

.bv-community__wrapper .bv-community__container .bv-community__icons .bv-community__icon-box .bv-community__icon {
	width: 60px
}

@media(min-width:1024px) {
	.bv-community__wrapper .bv-community__container .bv-community__icons .bv-community__icon-box .bv-community__icon {
		width: 90px
	}
}

.bv-india-night__section .clouds {
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	overflow: hidden;
	width: 100%;
	height: 100%
}

.bv-india-night__section .clouds img {
	position: absolute;
	z-index: -1;
	top: 0;
	max-width: 100%;
	opacity: .1;
	-webkit-animation: cloudAnimate calc(12s * var(--i)) linear infinite;
	animation: cloudAnimate calc(12s * var(--i)) linear infinite
}

@-webkit-keyframes cloudAnimate {
	0% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%)
	}

	to {
		-webkit-transform: translateX(100%);
		transform: translateX(100%)
	}
}

@keyframes cloudAnimate {
	0% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%)
	}

	to {
		-webkit-transform: translateX(100%);
		transform: translateX(100%)
	}
}

.bv-features__wrapper {
	overflow: hidden;
	max-width: 100%;
	padding-top: 80px
}

@media(min-width:768px) {
	.bv-features__wrapper {
		padding-top: 100px
	}
}

@media(min-width:1024px) {
	.bv-features__wrapper {
		padding-top: 140px
	}
}

.bv-features__wrapper .bv-features__container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1680px
}

@media(min-width:1024px) {
	.bv-features__wrapper .bv-features__container {
		padding: 0 30px
	}
}

@media(min-width:1680px) {
	.bv-features__wrapper .bv-features__container {
		padding: 0 80px
	}
}

.bv-features__wrapper .bv-features__container .bv-features__heading {
	font-family: var(--secondary-font);
	font-size: 1.65em;
	font-weight: 800;
	letter-spacing: -.01em;
	text-transform: uppercase
}

@media(min-width:640px) {
	.bv-features__wrapper .bv-features__container .bv-features__heading {
		font-size: 35px
	}
}

@media(min-width:1024px) {
	.bv-features__wrapper .bv-features__container .bv-features__heading {
		font-size: 45px
	}
}

.bv-features__wrapper .bv-features__swiper-section {
	padding-top: 30px
}

@media(min-width:1024px) {
	.bv-features__wrapper .bv-features__swiper-section {
		padding-top: 80px
	}
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper {
	overflow: visible
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-wrapper {
	padding-bottom: 20px
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide {
	width: 320px;
	height: auto
}

@media(min-width:768px) {
	.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide {
		width: 500px
	}
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide:before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 10px;
	top: 10px;
	z-index: -1;
	background: linear-gradient(-14deg, rgba(75, 0, 255, 0.5411764706) 0%, rgba(58, 150, 255, 0.0705882353) 44%)
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide.bv-features-poster {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide.bv-features-poster:before {
	background: linear-gradient(-14deg, rgba(81, 250, 147, 0.4196078431) 0%, rgba(58, 44, 209, 0.1215686275) 44%)
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide.bv-features-poster img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide .bv-swiper__slide-content {
	display: block;
	width: 100%;
	height: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: linear-gradient(-27deg, #141046 43%, #451d60 100%);
	color: #fff
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide .bv-swiper__slide-content:not(.bv-poster-content) {
	padding: 20px
}

@media(min-width:768px) {
	.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide .bv-swiper__slide-content:not(.bv-poster-content) {
		padding: 40px
	}
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide .bv-swiper__slide-content h3 {
	font-family: var(--secondary-font);
	font-size: 1.34em;
	font-weight: 700;
	letter-spacing: -.01em;
	line-height: 1.2;
	display: inline
}

@media(min-width:768px) {
	.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide .bv-swiper__slide-content h3 {
		font-size: 35px
	}
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide .bv-swiper__slide-content h3:after {
	content: "";
	display: inline-block;
	width: .35em;
	height: .35em;
	border-radius: 100%;
	margin-left: .3em;
	background-color: currentColor
}

.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide .bv-swiper__slide-content p {
	font-size: 1.1em;
	margin: 1.5em 0 0;
	line-height: 1.35
}

@media(min-width:768px) {
	.bv-features__wrapper .bv-features__swiper-section .bv-swiper .swiper-slide .bv-swiper__slide-content p {
		font-size: 1.25em
	}
}

.bv-roadmap__wrapper {
	display: block;
	padding: 80px 0 0;
	position: relative
}

@media(min-width:1280px) {
	.bv-roadmap__wrapper {
		padding-top: 140px
	}
}

.bv-roadmap__wrapper .bv-roadmap__bg-grid {
	position: absolute;
	display: block;
	height: 100%;
	width: 100%;
	z-index: -1;
	left: 0;
	top: 0;
	-webkit-mask-image: -webkit-gradient(linear, left bottom, left top, color-stop(77%, black), to(rgba(0, 0, 0, 0)));
	-webkit-mask-image: linear-gradient(360deg, black 77%, rgba(0, 0, 0, 0) 100%);
	mask-image: -webkit-gradient(linear, left bottom, left top, color-stop(77%, black), to(rgba(0, 0, 0, 0)));
	mask-image: linear-gradient(360deg, black 77%, rgba(0, 0, 0, 0) 100%)
}

@media(min-width:768px) {
	.bv-roadmap__wrapper .bv-roadmap__bg-grid {
		-webkit-mask-image: -webkit-gradient(linear, left bottom, left top, color-stop(32%, black), to(rgba(0, 0, 0, 0)));
		-webkit-mask-image: linear-gradient(360deg, black 32%, rgba(0, 0, 0, 0) 100%);
		mask-image: -webkit-gradient(linear, left bottom, left top, color-stop(32%, black), to(rgba(0, 0, 0, 0)));
		mask-image: linear-gradient(360deg, black 32%, rgba(0, 0, 0, 0) 100%)
	}
}

.bv-roadmap__wrapper .bv-roadmap__bg-grid .bv-roadmap__bg-grid--image {
	max-width: 100%;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	opacity: .65;
	-webkit-mask-image: linear-gradient(181deg, black 32%, rgba(0, 0, 0, 0) 100%);
	mask-image: linear-gradient(181deg, black 32%, rgba(0, 0, 0, 0) 100%)
}

.bv-roadmap__wrapper .bv-roadmap__container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1680px
}

@media(min-width:1024px) {
	.bv-roadmap__wrapper .bv-roadmap__container {
		padding: 0 30px
	}
}

@media(min-width:1680px) {
	.bv-roadmap__wrapper .bv-roadmap__container {
		padding: 0 80px
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-roadmap__heading {
	font-family: var(--secondary-font);
	letter-spacing: -.01em;
	font-weight: 800;
	line-height: 1.15;
	margin: 0 0 30px;
	font-size: 1.65em;
	text-align: left;
	text-transform: uppercase;
	background: linear-gradient(149deg, #ff5f14 10%, #e5fdff 50%, #48ffa4 85%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent
}

@media(min-width:640px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-roadmap__heading {
		font-size: 2em
	}
}

@media(min-width:768px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-roadmap__heading {
		font-size: 35px;
		margin: 0 0 80px
	}
}

@media(min-width:1024px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-roadmap__heading {
		font-size: 45px
	}
}

@media(min-width:1440px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-roadmap__heading {
		margin: 0 0 120px
	}
}

@media(min-width:768px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline {
		max-width: 1020px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 50px;
		padding-bottom: 50px
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline:after {
	content: "";
	position: absolute;
	height: 100%;
	width: 1px;
	display: block;
	top: 0;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(6, 3, 54, 0)), color-stop(15%, rgb(159, 11, 255)), color-stop(79%, rgb(11, 225, 255)), to(rgba(6, 3, 54, 0)));
	background: linear-gradient(180deg, rgba(6, 3, 54, 0) 0%, rgb(159, 11, 255) 15%, rgb(11, 225, 255) 79%, rgba(6, 3, 54, 0) 100%);
	left: 20px
}

@media(min-width:768px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline:after {
		left: 50%
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step {
	width: calc(100% - 20px);
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	margin-top: 20px;
	padding-left: 10px
}

@media(min-width:768px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		margin-top: 0;
		padding-left: 0;
		width: calc(50% + 10px);
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center
	}

	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step:nth-child(2n) {
		-ms-flex-item-align: end;
		align-self: flex-end;
		margin-right: 0
	}

	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step:nth-child(2n) .bv-roadmap__phase-num {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1
	}

	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step:nth-child(2n) .bv-roadmap__separator {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2
	}

	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step:nth-child(2n) .bv-roadmap__timeline-content {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content {
	background: linear-gradient(-27deg, #1F1967 43%, #3029AA 100%);
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	min-height: 120px;
	border: 1px solid transparent;
	-webkit-box-shadow: inset 0 1px 1px 0 rgba(34, 225, 255, .1215686275), -9px 10px 30px -13px rgba(0, 0, 0, .45);
	box-shadow: inset 0 1px 1px 0 rgba(34, 225, 255, .1215686275), -9px 10px 30px -13px rgba(0, 0, 0, .45)
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text {
	display: block;
	padding: 20px
}

@media(min-width:1680px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text {
		padding: 40px
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text h2 {
	font-family: var(--secondary-font);
	font-weight: 700;
	font-size: 18px;
	line-height: 1;
	margin: 0 0 .45em;
	letter-spacing: -.01em;
	text-transform: uppercase
}

@media(min-width:640px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text h2 {
		font-size: 20px
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text h3 {
	font-family: var(--secondary-font);
	font-size: 18px;
	font-weight: 400;
	margin: 0 0 8px
}

@media(min-width:640px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text h3 {
		font-size: 20px
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text p {
	font-size: 1em;
	margin: 0 0 10px
}

@media(min-width:640px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text p {
		font-size: 1.1em
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text>strong {
	font-family: var(--secondary-font);
	font-size: 14px
}

@media(min-width:640px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__timeline-content .bv-timeline__text>strong {
		font-size: 16px
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__separator {
	width: 40px;
	height: 2px;
	background: linear-gradient(266deg, #9f0bff 0%, rgba(0, 0, 0, 0) 80% 100%);
	margin-top: 30px
}

@media(min-width:768px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__separator {
		margin-top: 0;
		background: linear-gradient(88deg, #9f0bff 0%, rgba(0, 0, 0, 0) 80% 100%)
	}
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step:nth-child(2n) .bv-roadmap__separator {
	background: linear-gradient(266deg, rgb(159, 11, 255) 0%, rgba(0, 0, 0, 0) 80% 100%)
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__phase-num {
	background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, #010008), color-stop(86%, rgba(0, 0, 0, 0)));
	background: linear-gradient(180deg, #010008 30%, rgba(0, 0, 0, 0) 86%);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 20px;
	height: 20px;
	border-radius: 100%;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	line-height: 1;
	z-index: 1;
	margin-top: 20px;
	position: relative;
	-webkit-mask-image: linear-gradient(196deg, black 40%, rgba(0, 0, 0, 0) 80%);
	mask-image: linear-gradient(196deg, black 40%, rgba(0, 0, 0, 0) 80%)
}

.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__phase-num:before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	background: linear-gradient(139deg, #decdff 4%, #3b1eca 28%, #2cbbff 43%, rgba(6, 3, 52, 0) 86%);
	border-radius: 100%;
	position: absolute;
	z-index: -1;
	-webkit-mask-composite: xor;
	mask-composite: xor;
	-webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	padding: 2px
}

@media(min-width:768px) {
	.bv-roadmap__wrapper .bv-roadmap__container .bv-rodmap__timeline .bv-roadmap__timeline-step .bv-roadmap__phase-num {
		margin-top: 0
	}
}

.bv-faq__bg-grid {
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	width: 100%;
	height: auto;
	background: radial-gradient(circle 320px at -4% 50%, rgba(28, 75, 255, 0.7490196078) 0%, rgba(0, 0, 0, 0) 140%);
	-webkit-mask-image: -webkit-gradient(linear, left top, right top, from(rgba(7, 3, 53, 0)), color-stop(35%, rgb(0, 0, 0)));
	-webkit-mask-image: linear-gradient(90deg, rgba(7, 3, 53, 0) 0%, rgb(0, 0, 0) 35%);
	mask-image: -webkit-gradient(linear, left top, right top, from(rgba(7, 3, 53, 0)), color-stop(35%, rgb(0, 0, 0)));
	mask-image: linear-gradient(90deg, rgba(7, 3, 53, 0) 0%, rgb(0, 0, 0) 35%)
}

.bv-faq__bg-grid img {
	max-width: 100%;
	width: 1440px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	height: auto;
	opacity: .75;
	-webkit-mask-image: linear-gradient(266deg, rgba(7, 3, 53, 0) 0%, black 15%);
	mask-image: linear-gradient(266deg, rgba(7, 3, 53, 0) 0%, black 15%)
}

.bv-faq__container {
	display: block;
	padding: 120px 15px 0;
	position: relative;
	background: radial-gradient(circle 320px at -4% 50%, rgba(28, 75, 255, 0.4705882353) 0%, rgba(0, 0, 0, 0) 140%)
}

@media(min-width:1024px) {
	.bv-faq__container {
		padding: 120px 30px 0
	}
}

@media(min-width:1280px) {
	.bv-faq__container {
		padding: 120px 30px 0;
		background: 0 0
	}
}

@media(min-width:1680px) {
	.bv-faq__container {
		padding: 200px 80px 0
	}
}

.bv-faq__container .bv-faq__content-area {
	max-width: 1020px;
	margin: 0 auto
}

.bv-faq__container .bv-faq__content-area .bv-faq__heading {
	font-family: var(--secondary-font);
	letter-spacing: -.01em;
	font-weight: 800;
	line-height: 1.15;
	margin: 0 0 30px;
	font-size: 1.65em;
	text-align: left;
	text-transform: uppercase;
	background: linear-gradient(149deg, #ff5f14 10%, #e5fdff 20%, #48ffa4 58%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent
}

@media(min-width:640px) {
	.bv-faq__container .bv-faq__content-area .bv-faq__heading {
		font-size: 2em
	}
}

@media(min-width:768px) {
	.bv-faq__container .bv-faq__content-area .bv-faq__heading {
		font-size: 35px;
		margin: 0 0 80px;
		max-width: 17em
	}
}

@media(min-width:1024px) {
	.bv-faq__container .bv-faq__content-area .bv-faq__heading {
		font-size: 45px
	}
}

.bv-faq__container .bv-faq__content-area .bv-faq__accodion {
	border-width: 2px 0;
	border-style: solid;
	border-color: rgba(54, 64, 255, .36)
}

.bv-faq__container .bv-faq__content-area .bv-faq__accodion li {
	padding: 20px 0;
	border-bottom: 1px solid rgba(54, 64, 255, .76)
}

.bv-faq__container .bv-faq__content-area .bv-faq__accodion li:last-child {
	border-bottom: 0
}

.bv-faq__container .bv-faq__content-area .bv-faq__accodion li .bv-faq__accodion--question {
	position: relative;
	padding-right: 50px;
	font-family: var(--secondary-font);
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	line-height: 1.35;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	letter-spacing: -.02em
}

@media(min-width:1024px) {
	.bv-faq__container .bv-faq__content-area .bv-faq__accodion li .bv-faq__accodion--question {
		font-size: 20px
	}
}

.bv-faq__container .bv-faq__content-area .bv-faq__accodion li .bv-faq__accodion--question .bv-faq__accodion--cheveron {
	position: absolute;
	right: 0;
	top: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-items: center;
	width: 15px;
	height: auto;
	line-height: 0;
	opacity: .5;
	-webkit-transition: .55s;
	transition: .55s;
	-webkit-transform-origin: center;
	transform-origin: center
}

.bv-faq__container .bv-faq__content-area .bv-faq__accodion li .bv-faq__accodion--question .bv-faq__accodion--cheveron.is-open {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.bv-faq__container .bv-faq__content-area .bv-faq__accodion li .bv-faq__accodion--answer {
	display: none;
	padding: 15px 0 0;
	font-size: 16px;
	letter-spacing: .025em;
	line-height: 1.5;
	color: #c7e1ff;
	font-weight: 300
}

@media(min-width:768px) {
	.bv-litepapers {
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto
	}
}

.bv-litepapers .bv-litepaper__poster-img {
	max-width: 100%;
	width: 300px
}

@media(min-width:1440px) {
	.bv-litepapers .bv-litepaper__poster-img {
		width: 600px
	}
}

.bv-litepapers .bv-litepaper__download-link {
	font-family: var(--secondary-font);
	color: #fff;
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 10em;
	font-weight: 600;
	font-size: 17px;
	letter-spacing: .02em;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-transition: .45s;
	transition: .45s;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative;
	align-items: center;
	justify-content: center;
	max-width: 280px;
	margin-top: 2em
}

@media(min-width:640px) {
	.bv-litepapers .bv-litepaper__download-link {
		padding: 15px 30px 15px 20px
	}
}

.bv-litepapers .bv-litepaper__download-link:hover {
	-webkit-box-shadow: 0 0 100px 0 #594fff, 0 0 25px -10px #594fff;
	box-shadow: 0 0 100px 0 #594fff, 0 0 25px -10px #594fff
}

.bv-litepapers .bv-litepaper__download-link:hover:before {
	background: linear-gradient(332deg, #C65CFE 0%, #4249FE 47%)
}

.bv-litepapers .bv-litepaper__download-link:before {
	content: "";
	position: absolute;
	z-index: -1;
	inset: 0;
	background: linear-gradient(162deg, #C65CFE 0%, #4249FE 50%, #4CFFF7 100%);
	padding: 3px;
	border-radius: 10em;
	-webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	-webkit-transition: background .45s;
	transition: background .45s
}

.bv-litepapers .bv-litepaper__download-link .bv-download__cta-icon {
	display: block;
	width: 33px;
	height: auto;
	margin-right: 10px;
	-webkit-transform: translateY(2px) scale(1.12);
	transform: translateY(2px) scale(1.12)
}

.bv-litepapers .bv-litepapers__heading {
	font-weight: 600;
	font-size: 18px;
	line-height: 1.35;
	margin: 0 0 1em
}

@media(min-width:640px) {
	.bv-litepapers .bv-litepapers__heading {
		font-size: 24px
	}
}

.bv-litepapers .bv-litepapers__txt {
	line-height: 1.45;
	font-size: 18px
}

@media(min-width:640px) {
	.bv-litepapers .bv-litepapers__txt {
		font-size: 20px
	}
}

.bv-footer__wrapper {
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 15px;
	max-width: 1680px;
	position: relative;
	z-index: 1
}

@media(min-width:1024px) {
	.bv-footer__wrapper {
		padding: 0 30px;
		margin-top: 80px
	}
}

@media(min-width:1680px) {
	.bv-footer__wrapper {
		padding: 0 80px;
		margin-top: 120px
	}
}

.bv-footer__wrapper .bv-footer__container {
	padding-bottom: 80px
}

.bv-footer__wrapper .bv-footer__container:before {
	content: "";
	display: block;
	width: 100%;
	height: 1px;
	background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), color-stop(15%, rgb(139, 92, 246)), color-stop(85%, rgb(139, 92, 246)), to(rgba(0, 0, 0, 0)));
	background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(139, 92, 246) 15%, rgb(139, 92, 246) 85%, rgba(0, 0, 0, 0) 100%)
}

.bv-footer__wrapper .bv-footer__container .bv-footer__content {
	padding-top: 30px;
	text-align: center
}

@media(min-width:640px) {
	.bv-footer__wrapper .bv-footer__container .bv-footer__content {
		text-align: left
	}
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li {
	padding: 5px 0
}

@media(min-width:640px) {
	.bv-footer__wrapper .bv-footer__container .bv-footer__social li {
		padding: 0 5px
	}
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a {
	font-weight: 600;
	font-size: 16px;
	text-transform: uppercase;
	font-family: var(--secondary-font);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt {
	margin-left: 10px
}

@media(min-width:640px) {
	.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt {
		display: none
	}
}

@media(min-width:768px) {
	.bv-footer__wrapper .bv-footer__container .bv-footer__social li a {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}

	.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt {
		display: block;
		margin-left: 0
	}
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-icon {
	width: 30px;
	height: 30px;
	z-index: 1
}

@media(min-width:768px) {
	.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-icon {
		width: 40px;
		height: 50px;
		padding-bottom: 10px
	}
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-icon .icon-bg {
	fill: #6366f1;
	-webkit-transition: fill .45s;
	transition: fill .45s
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-icon .icon-shape {
	fill: #fff
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt:before {
	content: "";
	height: 10px;
	top: 100%;
	opacity: 0;
	position: absolute;
	width: 100%;
	left: 0;
	pointer-events: none
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt.txt-website:before {
	background: #ff4c4c
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt.txt-telegram:before {
	background: #08c
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt.txt-discord:before {
	background: #99aab5
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt.txt-facebook:before {
	background: #1877f2
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt.txt-instagram:before {
	background: #833ab4
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a .bv-footer__social-txt.txt-twitter:before {
	background: #1da1f2
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a:hover .bv-footer__social-icon.ic-website .icon-bg {
	fill: #ff4c4c
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a:hover .bv-footer__social-icon.ic-telegram .icon-bg {
	fill: #08c
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a:hover .bv-footer__social-icon.ic-discord .icon-bg {
	fill: #99aab5
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a:hover .bv-footer__social-icon.ic-facebook .icon-bg {
	fill: #1877f2
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a:hover .bv-footer__social-icon.ic-instagram .icon-bg {
	fill: #833ab4
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a:hover .bv-footer__social-icon.ic-twitter .icon-bg {
	fill: #1da1f2
}

.bv-footer__wrapper .bv-footer__container .bv-footer__social li a:hover .bv-footer__social-txt:before {
	opacity: 1;
	-webkit-animation: lineUp .3s ease forwards;
	animation: lineUp .3s ease forwards
}

@-webkit-keyframes lineUp {
	0% {
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		-webkit-transform: scale3d(1, .045, 1);
		transform: scale3d(1, .045, 1)
	}

	50% {
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}

	51% {
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}

	to {
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-transform: scale3d(1, .045, 1);
		transform: scale3d(1, .045, 1)
	}
}

@keyframes lineUp {
	0% {
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		-webkit-transform: scale3d(1, .045, 1);
		transform: scale3d(1, .045, 1)
	}

	50% {
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}

	51% {
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}

	to {
		-webkit-transform-origin: 50% 0%;
		transform-origin: 50% 0%;
		-webkit-transform: scale3d(1, .045, 1);
		transform: scale3d(1, .045, 1)
	}
}

.bv-multilang__languages-wrapper {
	-webkit-mask-image: linear-gradient(359deg, black 60%, rgba(0, 0, 0, 0) 91%);
	mask-image: linear-gradient(359deg, black 60%, rgba(0, 0, 0, 0) 91%);
	padding-top: 120px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-top: -240px
}

.bv-multilang__languages-wrapper .bv-multilang__languages {
	-webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
	mask-image: radial-gradient(circle, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)
}

.bv-multilang__languages-wrapper .bv-multilang__languages .bv-multilang__languages--img {
	max-width: 100%;
	height: auto;
	opacity: .25;
	-webkit-mask-image: linear-gradient(175deg, rgb(0, 0, 0) 43%, rgba(0, 0, 0, 0) 91%);
	mask-image: linear-gradient(175deg, rgb(0, 0, 0) 43%, rgba(0, 0, 0, 0) 91%)
}

@media(min-width:640px) {
	.bv-multilang__languages-wrapper {
		margin-top: -200px
	}
}

@media(min-width:768px) {
	.bv-multilang__languages-wrapper {
		margin-top: -140px
	}
}

@media(min-width:1280px) {
	.bv-multilang__languages-wrapper {
		margin-top: -200px
	}
}

.bv-multilang__bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	overflow: hidden;
	-webkit-mask-image: linear-gradient(359deg, black 60%, rgba(0, 0, 0, 0) 91%);
	mask-image: linear-gradient(359deg, black 60%, rgba(0, 0, 0, 0) 91%)
}

.bv-multilang__bg .bv-multilang__bg-grid--image {
	max-width: unset;
	width: 100%;
	height: 100%;
	opacity: .35;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
body{
        overflow-x: hidden;
}

.header-container {
  position: relative;
  height: 44px;
  overflow: hidden;
}
.header-container .logo-text {
  font-family: "Montserrat", sans-serif;
  font-size: 54px;
  text-align: center;
  line-height: 1;
  margin: 0;
  font-weight: 700;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #fff;
  text-shadow: -1px -1px 0 #20758a, 1px -1px 0 #20758a, -1px 1px 0 #20758a, 1px 1px 0 #20758a, 1px 0px 0px #6efbf8, 0px 1px 0px #6efbf8, 2px 1px 0px #6efbf8, 1px 2px 0px #6efbf8, 3px 2px 0px #6efbf8, 2px 3px 0px #6efbf8, 4px 3px 0px #6efbf8, 3px 4px 0px #6efbf8, 5px 4px 0px #6efbf8, 3px 5px 0px #20758a, 6px 5px 0px #20758a, -1px 2px 0 black, 0 3px 0 #20758a, 1px 4px 0 #20758a, 2px 5px 0px #20758a, 2px -1px 0 #20758a, 3px 0 0 #20758a, 4px 1px 0 #20758a, 5px 2px 0px #20758a, 6px 3px 0 #20758a, 7px 4px 0 #20758a, 2px 7px 28px #00fff7;
  -webkit-transform: scale(0.5) translate(0, -35px);
          transform: scale(0.5) translate(0, -35px);
  display: inline-block;
  -webkit-transform-origin: center;
          transform-origin: center;
  position: relative;
  letter-spacing: unset;
}
.header-container .logo-text::before {
  content: "";
  display: block;
  width: 180px;
  height: 180px;
  background: url(http://bharat/images/bharat-mandal-art.svg) center center no-repeat transparent;
  background-size: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -90px;
  margin-top: -90px;
  -webkit-transform-origin: 0%;
          transform-origin: 0%;
  z-index: -1;
  -webkit-animation: rotating 80s linear infinite;
          animation: rotating 80s linear infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
  border-radius: 100%;
  opacity: 0.45;
}
.header-container .logo-text::after {
  content: "BETA";
  position: absolute;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: white;
  z-index: 10;
  font-size: 18px;
  color: #000;
  padding: 6px 8px;
  border-radius: 5px;
  right: -80px;
  top: 0;
}
.header-container .logo-text ~ .beta {
  display: none;
}
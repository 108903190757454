.map-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
}

.current-location-btn {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    bottom: 30%;
    right: 9px;
    /* cursor: pointer; */
    /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
    url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
    auto; */
}

.current-location-btn :hover {
    background-color: whitesmoke;
}

.gm-style-iw {
    /* max-height: 300px !important;
    height:300px !important;     */
    overflow: hidden !important;
    height: auto !important;
    padding-right: unset !important;
    padding-bottom: unset !important;
    padding-top: unset !important;
    padding-left: unset !important;
    padding: 10px;
    width: auto !important;
    /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
    url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
    auto !important; */
}

.gm-style-iw-d {
    /* max-height: 300px !important;
    height:300px !important;     */
    overflow: hidden !important;
    height: auto !important;
    padding-right: unset !important;
    padding-bottom: unset !important;
    padding-top: unset !important;
    padding-left: unset !important;
    padding: 10px;
    width: auto !important;
    /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
    url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
    auto !important; */
    
}
/* .gm-style{
    cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
    url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
    auto !important;
} */

/* .gm-style:hover{
    cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
    url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
    auto !important;
} */

/* Infowindow CSS */
.place-name {
    font-size: 21px;
    font-weight: 500;
    color: #484BF6;
}

.coordinates-label {
    font-size: 8px;
    padding: 8px 0px;
}

.coordinate-icon {
    width: 12px;
    height: 12px;
}

.map-canvas { 
    position: absolute;    
    width: 100%;
    height: 100%;     
 }

.current-location-btn{
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    bottom: 30%;
    right: 9px;
    /* cursor: pointer; */
    /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
    url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
    auto; */
}

.current-location-btn :hover{
    background-color: whitesmoke;
}
.gm-style-iw {
    /* max-height: 300px !important;
    height:300px !important;     */
    overflow: hidden !important;    
    padding-right: unset !important;
    padding-bottom: unset !important;
    padding-top: unset !important;
    padding-left: unset !important;
    padding: 10px;
    height: auto !important;
    width: auto !important;
    /* height: auto !important; */
}
.gm-style-iw-d{
    /* max-height: 300px !important;
    height:300px !important;     */
    overflow: hidden !important;    
    padding-right: unset !important;
    padding-bottom: unset !important;
    padding-top: unset !important;
    padding-left: unset !important;
    padding: 10px;
    height: auto !important;
    width: auto !important;
    /* height: auto !important; */
    
}

.textarea-btn{
    position: absolute;
    width: 100px;
    height: 60px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    bottom: 50%;
    right: 9px;
    /* cursor: pointer; */
    /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
    url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
    auto; */
}

.clear-btn{
    position: absolute;
    width: 75px;
    height: 25px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    bottom: 45%;
    right: 9px;
    /* cursor: pointer; */
    /* cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),
    url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), 
    auto; */
}

.clear-label{
    text-align: center;
    align-self: self-start;
}